.container {
  display: none;
}

.image {
  max-width: 98%;
  max-height: 10rem;
  /*calulate height of image by width of screen */

  margin: 1%;
  border: 2px solid var(--color-gold-light);
}
@media (max-width: 950px) {
  /* make container full size top of screen */
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20rem;
  }
}

@media (max-width: 600px) {
  .image {
    max-height: 7rem;
  }
  .container {
    height: 10rem;
  }
}
@media (max-width: 400px) {
  .image {
    max-height: 5rem;
  }
  .container {
    height: 6rem;
  }
}
