.container {
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(5px);
	z-index: 1001 !important;
}

.container::before {
	content: "";
	position: absolute;
	inset: 0;
	background-color: var(--color-primary-100);
	opacity: 0.85;
}

.element {
	max-width: 30rem;
	width: 100%;
	padding: 2.15rem;
	background-color: var(--color-primary-400);
	border-radius: var(--border-radius-large);
	outline: var(--border-size) solid var(--color-secondary-100);
	position: relative;
}

.title {
	margin-bottom: 0.65em;
}

.body {
	margin-bottom: 2.15em;
}

.container-button {
	width: fit-content;
	margin: 0 auto;
}

@media (max-width: 550px), (max-height: 350px) {
	.container {
		display: block;
		overflow-y: scroll;
	}

	.element {
		max-width: 100%;
		height: fit-content;
		box-sizing: border-box;
		border-radius: 0;
	}

	.container-button {
		width: 100%;
		margin: 0;
	}
}
