.container-full-splash {
  min-height: 40em;
  margin-bottom: 10em;
  position: relative;
  background-color: var(--color-dark-400);
  color: var(--color-primary-100);
  align-items: center;
  flex-direction: column;
}

.container-partial-splash {
  height: fit-content;
  position: relative;
}

.splash-info {
  position: relative;
  z-index: 1;
}

.splash-logo {
  margin: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 5px solid var(--color-gold-light);
  margin-bottom: 3rem;
  /* gradient */
  background-image: linear-gradient(
    45deg,
    var(--color-blue-dark),
    var(--color-blue-light)
  );
}
/*span*/
.splash-logo span {
  color: var(--color-primary-100);
  /*minimalist font*/
  font-weight: 600 !important;
}
.splash-logo-text {
  font-size: 7rem;

  color: var(--color-gold-light);

  padding: 1rem;
}

.splash-info::before {
  content: "";
  --_scale: 1;
  --_translate_y: -30%;
  width: 100%;
  height: calc(365px * 1.12);
  position: absolute;
  background-size: 35%;
  background-position-x: left;
  background-position: relative;

  background-repeat: no-repeat;
  transform: scale(var(--_scale)) translateY(var(--_translate_y));

  z-index: -1;
}
@media screen and (min-width: 1350px) and (max-width: 1650px) {
  .splash-info::before {
    background-position-y: 15%;
    background-position-x: 11%;
  }
}
@media (max-width: 950px) {
  .splash-logo {
    display: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .splash-info::before {
    animation-name: splash-logo-intro;
    animation-timing-function: var(--transition-timing-function-elastic);
    animation-duration: 850ms;
  }

  @keyframes splash-logo-intro {
    0% {
      transform: scale(1.15) translateY(var(--_translate_y));
      opacity: 0.35;
    }
    100% {
      transform: scale(var(--_scale)) translateY(var(--_translate_y));
      opacity: 1;
    }
  }
}

.container-partial-splash button:focus {
  outline-color: var(--color-primary-400) !important;
}

.heading {
  margin-left: 2rem;
  color: var(--color-light-100);
}

.heading span:first-child {
  color: var(--color-blue-dark);
}

.heading span:last-child {
  color: var(--color-accent-400);
}

.tagline {
  margin-top: 1em;
  font-size: 1.35rem;
  font-weight: 400;
  line-height: 2.15rem;
  color: var(--color-primary-400);
}

.container-full-splash input {
  background-color: var(--color-secondary-400);
}

.splash-demo {
  position: absolute;
  inset: 0 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  user-select: none;
}

.splash-demo-container-image {
  --_scale-factor: 0.55;
  width: calc(1310px * var(--_scale-factor));
  height: calc(894px * var(--_scale-factor));
  margin-right: -5em;
  position: relative;
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255) 85%
  );
}

.splash-demo-image {
  object-fit: contain;
}

@media (prefers-reduced-motion: no-preference) {
  .splash-demo-container-image {
    animation-name: splash-demo-intro;
    animation-timing-function: var(--transition-timing-function-elastic);
    animation-duration: 850ms;
  }

  @keyframes splash-demo-intro {
    0% {
      transform: translateX(-85px);
      opacity: 0.35;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.wave {
  width: 100%;
  min-width: 85rem !important;
  height: 45%;
  min-width: 65rem;
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  bottom: 0;
  transform: scaleX(2);
  pointer-events: none;
  user-select: none;
}

.wave svg {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}

.wave-background {
  height: 50%;
  /* move down a bit */
  bottom: -40%;
  animation-duration: calc(12.35s * 1.5);
}

.wave-middleground {
  bottom: -30%;

  animation-duration: calc(9.65s * 1.5);
}

.wave-foreground {
  bottom: -1px; /* fix seam */
  bottom: -30%;

  animation-duration: calc(5.15s * 1.5);
}

@media (prefers-reduced-motion: no-preference) {
  .wave {
    animation-name: animateWave;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }

  @keyframes animateWave {
    0% {
      transform: translateX(0) scaleX(2);
    }
    50% {
      transform: translateX(-50%) scaleX(2);
    }
    100% {
      transform: translateX(0) scaleX(2);
    }
  }
}

@media (max-width: 1650px) {
  .splash-info::before {
    --_scale: 1.15;
  }
}

@media (max-width: 1350px) {
  .splash-info::before {
    --_scale: 1;
  }

  .splash-demo-container-image {
    margin-right: 0;
    mask-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.15) 15%,
      rgba(255, 255, 255, 0.35) 65%,
      rgb(255, 255, 255)
    );
  }
}

@media (max-width: 1150px) {
  .splash-demo-container-image {
    --_scale-factor: 0.4;
  }
}

@media (max-width: 950px) {
  .container-full-splash {
    min-height: 40em;
  }

  .container-partial-splash {
    margin-top: 3.5em;
  }

  .splash-info::before {
    --_translate_y: 3.5vh;
  }

  .heading {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
  }

  .tagline {
    margin-top: 0.85em;
  }

  .splash-demo {
    align-items: flex-start;
    justify-content: center;
  }

  .splash-demo-container-image {
    --_scale-factor: 0.35;
    margin-top: -1.5em;
    mask-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.1) 15%,
      rgba(255, 255, 255, 0.3) 75%,
      rgb(255, 255, 255)
    );
  }

  .wave {
    height: 35%;
  }

  .wave-background {
    height: 38%;
  }
}

@media (max-width: 850px) {
  .container-full-splash {
    min-height: 30em;
  }

  .container-partial-splash {
    margin-top: 1.5em;
  }

  .splash-demo-container-image {
    --_scale-factor: 0.35;
    margin-top: 0;
    mask-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.05) 15%,
      rgba(255, 255, 255, 0.25) 85%,
      rgb(255, 255, 255)
    );
  }

  .wave {
    height: 20%;
  }

  .wave-background {
    height: 23%;
  }
}

@media (max-width: 650px) {
  .container-full-splash {
    min-height: 40em;
  }

  .splash-info::before {
    --_translate_y: 5vh;
  }
}

@media (max-width: 500px) {
  .container-full-splash {
    min-height: 42em;
  }

  .splash-info::before {
    --_translate_y: 15vh;
  }

  .splash-demo-container-image {
    margin-top: -5vh;
  }
}

@media (max-width: 400px) {
  .splash-demo-container-image {
    margin-top: -10vh;
  }
}
