.container-element {
	width: 100%;
	height: 3.35rem;
	display: flex;
	flex-direction: column;
	position: relative;
}

.element:not(:last-child) {
	margin-bottom: var(--spacing-large);
}

.container-element label {
	width: fit-content;
	margin-bottom: 0.5em;
	font-size: 1.1rem;
}

.container-element label span {
	font-size: 0.65rem;
	font-style: italic;
}

.element {
	height: 100%;
	padding: 0.85rem;
	font-size: 1rem;
	font-weight: 400;
	color: var(--color-primary-400);
	background-color: transparent;
	border: var(--border-size) solid var(--color-primary-400);
	border-radius: var(--border-radius-small);
	user-select: auto;
	position: relative;
	transition-property: transform;
	transition-duration: 100ms;
	transition-timing-function: ease-out;
}

.container-element-secondary .element {
	color: var(--color-secondary-100);
	border: var(--border-size) solid var(--color-secondary-100);
}

.element:placeholder-shown {
	background-color: transparent;
}

.container-element:not(.container-element-secondary)
	.element:not(:placeholder-shown) {
	background-color: var(--color-secondary-400);
}

.element:hover {
	transform: scale(1.035);
}

.element:focus {
	outline: 5px solid var(--color-accent-100);
	border: var(--border-size) solid var(--color-accent-400);
	box-shadow: none;
}

.element::placeholder {
	color: var(--color-primary-400);
	opacity: 0.65;
}

.container-element-secondary .element:focus {
	border-color: var(--color-secondary-100);

	border-radius: var(--border-radius-small);
	outline-offset: var(--border-size);
	outline: var(--border-size) solid var(--color-primary-400);
	position: relative;
	z-index: 1;
}

.element-error .element {
	border-color: var(--color-error);
}

.container-element-textarea {
	height: fit-content;
}

.container-element-textarea .element {
	max-height: 35em;
	height: 10.05em;
	min-height: 10.05em;
	border-color: var(--color-secondary-100);
	resize: vertical;
}

.container-element-textarea .element::placeholder {
	color: var(--color-secondary-100);
}

.container-element-textarea .element:hover {
	transform: none;
}

.container-element-textarea .element:focus {
	border-color: var(--color-accent-400);
}
