.container-full {
  background-color: var(--color-primary-400);
  color: var(--color-secondary-100);
  position: relative;
}

.container-partial {
  margin: 3.5em 0 var(--partial-spacing) 0;
}

.container-partial h2 {
  margin-bottom: 2.65rem;
  font-size: 2.35rem;
  text-align: center;
}

.container-partial-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-bottom: 5rem;
}

.feature {
  padding: 1.15em;
  box-sizing: border-box;
  border: var(--border-size) solid var(--color-accent-400);
  background: var(--color-primary-400);
  background: linear-gradient(145deg, rgba(255, 107, 123, 0) 0%, #305486 100%);
  box-shadow: var(--shadow-card);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  word-wrap: break-word;
  z-index: 1;
}
.feature-light {
  padding: 1.15em;
  box-sizing: border-box;
  border: var(--border-size) solid rgba(201, 225, 245, 0.853);
  background: var(--color-accent-400);
  background: linear-gradient(145deg, rgba(255, 107, 123, 0) 0%, #d7b162 100%);
  box-shadow: var(--shadow-card);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  word-wrap: break-word;
  z-index: 1;
}
.feature-light i {
  color: rgba(201, 225, 245, 0.853);
  font-size: 2.85rem;
  display: flex;
  flex-direction: row;
}
.feature-icon-text {
  color: rgba(201, 225, 245, 0.853);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.feature-light p {
  margin-top: 1.15em;
  width: 100%;
}

.feature i {
  color: var(--color-accent-400);
  font-size: 2.85rem;
}

.feature p {
  margin-top: 1.15em;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .feature {
    animation-name: featureEnter;
    animation-timing-function: var(--transition-timing-function-elastic);
    animation-duration: 850ms;
    animation-fill-mode: backwards;
    animation-play-state: paused;
  }

  .animation .feature {
    animation-play-state: running;
  }

  .feature:nth-child(1) {
    -webkit-animation-delay: 0;
    animation-delay: 0;
  }

  .feature:nth-child(2) {
    -webkit-animation-delay: calc(65ms * 1);
    animation-delay: calc(65ms * 1);
  }

  .feature:nth-child(3) {
    -webkit-animation-delay: calc(65ms * 2);
    animation-delay: calc(65ms * 2);
  }

  .feature:nth-child(4) {
    -webkit-animation-delay: calc(65ms * 3);
    animation-delay: calc(65ms * 3);
  }

  .feature:nth-child(5) {
    -webkit-animation-delay: calc(65ms * 4);
    animation-delay: calc(65ms * 4);
  }

  .feature:nth-child(6) {
    -webkit-animation-delay: calc(65ms * 5);
    animation-delay: calc(65ms * 5);
  }

  @keyframes featureEnter {
    0% {
      transform: scale(0.15);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@media (max-width: 1200px) {
  .container-partial-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 850px) {
  .container-partial h2 {
    font-size: 1.65rem;
  }

  .container-partial-list {
    gap: 1.5rem;
  }

  .feature i {
    font-size: 2.5rem;
  }

  .feature p {
    margin-top: 0.85em;
  }
}

@media (min-width: 750px) {
  .intersection-dectector {
    position: absolute;
    top: 50%;
    visibility: hidden;
  }
}

@media (max-width: 750px) {
  .container-partial-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 400px) {
  /* .container-partial h2 {
		margin-bottom: 1.65rem;
		font-size: 1.65rem;
	} */
}
