.container-full {
  background-color: var(--color-primary-400);
  color: var(--color-secondary-100);
  position: relative;
}
.container-form {
  margin-top: 1.85em;
  display: flex;
  flex-direction: row;
  gap: 0.35em;
  justify-content: space-between;
}
@media (max-width: 950px) {
  .container-form {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
.container-form-input {
  width: 66.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form-dropdowns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.35em;
}

.container-form-input small {
  color: var(--color-primary-400);
}

.container-form-button {
  width: fit-content;
}

@media (max-width: 650px) {
  .container-form {
    width: 100%;
  }

  .container-form {
    flex-direction: column;
    justify-content: flex-start;
  }

  .container-form-input {
    width: 100%;
    margin-bottom: 2rem;
  }

  .container-form-button {
    width: 15em;
    margin: 1.35em auto 0 auto;
  }

  .container-form + small {
    width: 100%;
  }
}

/* Independant */

.container-partial {
  margin: var(--partial-spacing) 0;
}

.container-content {
  padding: 2.65em;
  border-radius: var(--border-radius-large);
  background-color: var(--color-accent-400);
  box-shadow: var(--shadow-card);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container-content::before {
  content: "";
  position: absolute;
  border-radius: var(--border-radius-large);
  inset: 0;
  background-image: linear-gradient(
      35deg,
      var(--color-blue-dark),
      var(--color-accent-400)
    ),
    none;
  background-size: cover;
  z-index: 0;
}

.container-info {
  z-index: 1;
}

.container-content .heading {
  margin-bottom: 1em;
  font-size: 2.85rem;
  line-height: 3.85rem;
  text-align: center;
}

.container-content .container-form {
  margin: 0;
}

.container-content .container-form input::placeholder {
  color: var(--color-secondary-100);
}

.container-content small {
  color: var(--color-secondary-100);
}

.container-graphic {
  width: 28em;
  height: 14.15em;
  position: relative;
  border-radius: var(--border-radius-large);
  overflow-x: visible;
}

@media (max-width: 1200px) {
  .container-full {
    margin-top: var(--partial-spacing);
  }

  .container-graphic {
    width: calc(28em * 0.75);
    height: calc(14.15em * 0.75);
  }
}

@media (max-width: 950px) {
  .container-graphic {
    width: calc(28em * 0.65);
    height: calc(14.15em * 0.65);
  }
}

@media (max-width: 850px) {
  .container-content {
    padding: 1.65em;
    flex-direction: column-reverse;
    align-items: center;
  }

  .container-info {
    width: 100%;
    margin-top: 0.65em;
  }

  .container-content .heading {
    max-width: 12em;
    margin-bottom: 0.85em;
    font-size: 2.35rem;
    line-height: 3.35rem;
  }

  .container-graphic {
    width: calc(28em * 0.75);
    height: calc(14.15em * 0.75);
  }
  .container-full {
    margin-top: 0;
  }
}

@media (max-width: 400px) {
  .container-content .heading {
    margin-bottom: 0.85em;
    font-size: 2rem;
    line-height: 2.85rem;
  }

  .container-graphic {
    width: calc(28em * 0.65);
    height: calc(14.15em * 0.65);
  }
}
