.element {
  width: 8.5em;
  height: 3.35rem;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: var(--border-radius-small);
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color-secondary-100);
  border: var(--border-size) solid var(--color-dark-400);
  flex-grow: 0;
  position: relative;
  cursor: pointer;
  transition-property: transform, opacity;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  z-index: 0;
}

.element-secondary {
  color: var(--color-accent-400);
  border-color: var(--color-secondary-400);
}

.element::before {
  content: "";
  position: absolute;
  inset: -1px;
  border-radius: var(--border-radius-small);
  z-index: -1;
  transition-property: opacity;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
}

.element::before {
  background-color: var(--color-dark-400);
}

.element-secondary::before {
  background-color: var(--color-secondary-400);
}

.element:hover {
  transform: scale(1.065);
}

.element:hover::before {
  opacity: 0.75;
}

.element:focus {
  outline-offset: var(--border-size);
  outline: var(--border-size) solid var(--color-secondary-100);
}

.element:active {
  transition: none;
  transform: scale(0.95);
  opacity: 0.35;
}

@media (max-width: 650px) {
  .element {
    width: 100%;
  }

  .element:hover {
    transform: scale(1.05);
  }
}
